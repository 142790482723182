export const ROUTES_PATHS = {
  ADMINISTRATION: "/administration/*",
  ALERTS: "/incidents",
  APITTOKENS2: "/api-tokens2",
  API_TOKENS: "/api-tokens",
  CYBERHAVEN_LOGIN: "/cyberhaven-login",
  DATAFLOWS_DASHBOARD: "/dataflows-overview",
  ENDPOINT: "/sensors/endpoint/*",
  FORGOT_PASSWORD: "/forgot",
  GRAPHIQL: "/gapi",
  JWT_AUTH: "/__jwt-auth",
  LOGIN: "/login",
  MONITORING: "/monitoring",
  PORTAL: "/portal",
  PREFERENCES: "/preferences/*",
  PROFILE: "/profile",
  REGISTRATION: "/signup",
  RESET: "/reset",
  RISKS_DASHBOARD: "/overview",
  ROLES: "/roles",
  SENSORS: "/sensors/cloud",
  SHARABLE_LISTVIEW: "/trace-details/:stateId",
  USERS_V2: "/users",
  ANALYTICS_DASHBOARD: (supersetPath = "") => `/analytics${supersetPath}`,
  INSIDER_RISKS: "/insider-risks",
  LISTS_MATCHING: "/lists-matching",
  LANDING: "/home",
} as const;

// This path should be used for user with minimal permission set - ability to log in
export const HOME_PAGE_FALLBACK_PATH = ROUTES_PATHS.PROFILE;
