import { resolveService } from "service";
import { upperFirst, lowerCase } from "lodash";
import { Services } from "app/externalServicesTypes";
import { IUser } from "api/auth/interfaces";
export async function logEvent(text: string, p?: any) {
  const logger = await resolveService("logger");
  logger.event(text, p);
}

export async function setupLogger(
  pendoP: Promise<Services["pendo"]>,
  fullstoryP: Promise<Services["fullstory"]>,
  intercomP: Promise<Services["intercom"]>,
  errorReporterP: Promise<Services["errorReporter"]>
) {
  const errorReporterd = await errorReporterP;
  const pendo = await pendoP;
  const fullstory = await fullstoryP;
  const intercom = await intercomP;

  async function trackEvent(name: string, vars: Record<string, string>) {
    try {
      pendo.track(name, vars);
      fullstory.event(name, vars);
      intercom("trackEvent", name, vars);
    } catch (e: any) {
      errorReporterd.report(e);
      console.error(e);
    }
  }

  return {
    event: trackEvent,
    error: (args: any) => errorReporterd.report(args, { skipLocalFrames: 2 }),
  };
}

export function getConfig(user: IUser, customerProps: { id: string; [k: string]: any }) {
  // Use email as id for Cyberhaven users, otherwise use the id. Cyberhaven users are recreated quite often which creates issues with pendo pricing.
  // this is important to keep _ to preserve user stats for existing users.
  const userId = user.email?.endsWith("@cyberhaven.com")
    ? `${btoa(user.email)}_${customerProps.id}`
    : `${user.id}_`;
  const userProps = {
    id: userId,
    customer: customerProps.id,
    email: user.email,
  };

  return {
    visitor: userProps,
    account: customerProps,
    excludeAllText: true,
    excludeTitle: true,
    guides: { disable: false },
  };
}

const isPendoDesignerRequested = window.location.search.indexOf("pendo-designer") !== -1;

// This function checks if pendo or intercom edit mode is active at this moment.
// We're disabling MUI Dialog modal focus enforcement in such cases, otherwise edit mode won't work.
export function isThirdPartyEditMode() {
  return (
    isPendoDesignerRequested ||
    document.querySelectorAll("body > #pendo-action-bar-container, body > #intersection-container")
      .length > 0
  );
}

interface EventsConstants {
  [key: string]: EventsConstants | string;
}

function mapKeys<T extends EventsConstants>(obj: T, prefix?: string): T {
  const fullPrefix = prefix ? prefix + " / " : "";
  return Object.entries(obj).reduce((res, [key, value]) => {
    res[key] =
      typeof value === "object"
        ? mapKeys(value, fullPrefix + (value[".."] || upperFirst(lowerCase(key))))
        : fullPrefix + (value || upperFirst(lowerCase(key)));
    return res;
  }, {} as any);
}

const CRUD = {
  create: "",
  update: "",
  delete: "",
};

/* Events are logged for analytics purposes, in addition to clicks.
   Please use events for things that change data, like creating a dataset
   (usually such events cause changes on the backend), or events that are
   important for "marking" users as thouse who accomplished a milestone.
   Otherwise, prefer tracking clicks through data-testid. */
export const eventsConstants = mapKeys({
  // Authentication
  auth: {
    login: "",
    logout: "",
  },

  // Graph
  graph: {
    "..": "Map",
    "search": {
      performSearch: "",

      templates: {
        ...CRUD,
        updateMonitoring: "",
      },
    },
  },

  // Reports
  reports: {
    ...CRUD,
    addChart: "",
    removeChart: "",
  },

  // Risks dashboard
  dashboard: {
    datasets: CRUD,
    categories: CRUD,
  },

  // Preferences
  preferences: {
    users: {
      ...CRUD,
      inviteUser: "",
      updatePassword: "",
    },
    betas: {
      feature: "",
    },
    timezone: {
      rules: CRUD,
    },
    contentInspection: {
      rules: CRUD,
    },
    definitionOfInternal: {
      rules: CRUD,
    },
    directory: {
      ...CRUD,
    },
  },

  incidents: {
    markAs: "",
    bulkMarkAs: "",
    viewDetails: "",
    assignTo: "",
    bultAssignTo: "",
  },

  // Listview (in multiple pages)
  listView: {
    // unfortunately we can't track this with clicks due to auto-expanding
    viewFlowDetails: "",
  },
});
